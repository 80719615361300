import { FormattedMessage } from 'react-intl'
import React from 'react'

interface IProps {
  title: string
  language?: string
}

const Header = React.memo<IProps>((props) => {
//const Header: React.memo(props => {
  return (
    <div className="page-header centered">
      <h1><FormattedMessage id={props.title}/></h1>
    </div>
  )
})

//export default React.memo(Header)
export default Header