const flyerList = [
    { imagePath: './images/flyers/2018_09_12.jpg', name: '3ο τετράμηνο 2018' },
    { imagePath: './images/flyers/2018_05_08.jpg', name: '2ο τετράμηνο 2018' },
    { imagePath: './images/flyers/2018_01_04.jpg', name: '1ο τετράμηνο 2018' },
    { imagePath: './images/flyers/2017_09_12.jpg', name: '3ο τετράμηνο 2017' },
    { imagePath: './images/flyers/2017_05_08.jpg', name: '2ο τετράμηνο 2017' },
    { imagePath: './images/flyers/2017_01_04.jpg', name: '1ο τετράμηνο 2017' },
    { imagePath: './images/flyers/2016_09_12.jpg', name: '3ο τετράμηνο 2016' },
    { imagePath: './images/flyers/2016_05_08.jpg', name: '2ο τετράμηνο 2016' },
    { imagePath: './images/flyers/2016_01_04.jpg', name: '1ο τετράμηνο 2016' },
    { imagePath: './images/flyers/2015_09_12.jpg', name: '3ο τετράμηνο 2015' },
    { imagePath: './images/flyers/2015_06_08.jpg', name: 'Flyer 2015b' },
    { imagePath: './images/flyers/2015_02_04.jpg', name: 'Flyer 2015a' },
]

export default () => {
  return (
    <div className="row col-12">
      {
        flyerList.map(function(flyer, index){
        return (
          <div className="col-6" key={index}>
            <img src={flyer.imagePath} className="img-responsive" style={{ width: '100%' }} alt="Flyer"/>
            <div>{flyer.name}</div>
          </div>
        )
        })
      }
    </div>
  )
}