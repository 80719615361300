import React from 'react'
import DataComponent from '../hoc/DataComponent'
import { withRouter } from 'react-router-dom'
import { PhonecardsContext } from '../contexts/PhonecardsContext'
import IOrderInfo from '../interfaces/IOrderInfo'

const PersonalInfoForm: React.FC = () => {
  return (
    <PhonecardsContext.Consumer>
      {({ sendEmail, changeValue, orderInfo }: {sendEmail(event: any): void, changeValue(event: any, field: string): void, orderInfo: IOrderInfo}) => (
        <form autoComplete="on" onSubmit={(event) => {event.preventDefault(); sendEmail(event)}}>
          <div className="form-horizontal centered mt-5" style={{ width: 700 }}>
            <div className="form-group">
              <div className="col-sm-10">
                <input name="name" type="text" id="name" className="form-control" required={true} placeholder="Ονοματεπώνυμο" value={orderInfo.name} onChange={(event) => changeValue(event, 'name')}/>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-10">
                <input name=" address" type="text" id="address" className="form-control" placeholder="Διεύθυνση" value={orderInfo.address} onChange={(event) => changeValue(event, 'address')}/>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-6">
                <input name=" city" type="text" id="city" className="form-control" placeholder="Πόλη" value={orderInfo.city} onChange={(event) => changeValue(event, 'city')}/>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-6">
                <input name="postalCode" type="text" id=" postalCode" className="form-control" placeholder="Τ.Κ." value={orderInfo.postalCode} onChange={(event) => changeValue(event, 'postalCode')}/>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-6">
                <input name="phone" type="text" id="phone" required={true} className="form-control" placeholder="Τηλέφωνο Επικοινωνίας" value={orderInfo.phone} onChange={(event) => changeValue(event, 'phone')}/>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-10">
                <input name="email" type="text" id="email" className="form-control" required={true} placeholder="Email" value={orderInfo.email} onChange={(event) => changeValue(event, 'email')}/>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-6">
                <select name="paymentType" id="paymentType" className="form-control" style={{ width: 200 }} value={orderInfo.paymentType} onChange={(event) => changeValue(event, 'paymentType')}>
                  <option value="Μετρητά">Μετρητά</option>
                  <option value="Αντικαταβολή">Αντικαταβολή</option>
                  <option value="Κατάθεση σε Τραπεζικό Λογαριασμο">Κατάθεση σε Τραπεζικό Λογαριασμο</option>
                  <option value="Με συστημένο γράμμα">Με συστημένο γράμμα</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-10">
                <input name="comments" type="text" id="comments" className="form-control" placeholder="Σχόλια" value={orderInfo.comments} onChange={(event) => changeValue(event, 'comments')}/>
              </div>
            </div>
          </div>
          <input type="submit" value="Αποστολή Παραγγελίας" className="btn btn-primary"/>
        </form>
      )}
    </PhonecardsContext.Consumer>
)
}

export default withRouter(DataComponent(PersonalInfoForm, '', true))