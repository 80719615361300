import React, { Component } from 'react'
import axios from 'axios'
import LoadingIndicator from '../components/LoadingIndicator'
import { isPHP, linksMap } from '../common/constants'

// PureComponent is exactly the same as Component except that it handles the shouldComponentUpdate method for you.
// When props or state changes, PureComponent will do a shallow comparison on both props and state.
// Component on the other hand won’t compare current props and state to next out of the box.
// Thus, the component will re-render by default whenever shouldComponentUpdate is called.
export default (WrappedComponent, url, statisticsData) =>
  class extends Component {
    constructor(props) {
      super(props)
      //console.log('constructor')
      this.state = {
        data: [],
        loading: false
      }
    }

    //shouldComponentUpdate = (nextProps, nextState) => {
    //  console.log('SHOULD UPDATE?', this.props.location.pathname !== nextProps.location.pathname)
    //  return this.props.location.pathname !== nextProps.location.pathname
   // }

    retrieveData = () => {
      this.setState({ loading: true, data: [] })

      let finalUrl = this.props.match.url
      if (this.props.match.url !== '/' && this.props.match.url.indexOf('/', 7) !== -1) {
        finalUrl = this.props.match.url.substring(0, this.props.match.url.indexOf('/', 7))
      }
      //console.log('1111------111111111', finalUrl, this.props.match.url)
      finalUrl = finalUrl ? finalUrl : '/'
      //console.log('1111111111111', finalUrl)
      let definedUrl = url.indexOf('news') > 0 ? url : (this.props.location ? this.props.match.url.replace(finalUrl, linksMap[finalUrl].url) : url)

      //console.log('datacomponent', definedUrl)
      if (statisticsData) {
        if (isPHP) {
          if (definedUrl === '/api/phonecards/phonecards') {
            definedUrl = '/api/statistics.php?type=phonecards'
          } else if (definedUrl === '/api/phonecards/collectibles') {
            definedUrl = '/api/statistics.php?type=collectibles'
          } else if (definedUrl === '/api/phonecards/chronocards') {
            definedUrl = '/api/statistics.php?type=chronocards'
          }
        } else {
          definedUrl = definedUrl.replace('/phonecards', '/statistics')
        }
      } else {
        if (isPHP) {
          if (definedUrl === '/api/phonecards/phonecards') {
            definedUrl = '/api/cards.php?type=phonecards'
          } else if (definedUrl === '/api/phonecards/collectibles') {
            definedUrl = '/api/cards.php?type=collectibles'
          } else if (definedUrl === '/api/phonecards/chronocards') {
            definedUrl = '/api/cards.php?type=chronocards'
          } else if (definedUrl === '/api/phonecards/cyprus') {
            definedUrl = '/api/cards.php?type=cyprus'
          } else if (definedUrl === '/api/phonecards/ex') {
            definedUrl = '/api/cards.php?type=ex'
          } else if (definedUrl === '/api/phonecards/otn') {
            definedUrl = '/api/cards.php?type=otn'
          } else if (definedUrl === '/api/phonecards/str') {
            definedUrl = '/api/cards.php?type=str'
          } else if (definedUrl === '/api/phonecards/vf') {
            definedUrl = '/api/cards.php?type=vf'
          } else if (definedUrl === '/api/phonecards/cos') {
            definedUrl = '/api/cards.php?type=cos'
          } else if (definedUrl === '/api/phonecards/pan') {
            definedUrl = '/api/cards.php?type=pan'
          } else if (definedUrl === '/api/phonecards/tel') {
            definedUrl = '/api/cards.php?type=tel'
          }
        }
      }

      axios.get(definedUrl)
        .then((response) => {
          //console.log(response.data)
          this.setState({ data: response.data, loading: false })
        })
        .catch(error => {
          console.log('----------------------------------------------------', error)
          this.setState({ data: [], loading: false })
        })

/*      axios.get(definedUrl.replace('/phonecards', '/statistics'))
        .then((response) => {
          console.log('-----statistics---------', response.data)
          this.setState({ ...this.state, statistics: response.data})
        })
        .catch(error => {
          console.log('----------------------------------------------------', error)
          this.setState({ ...this.state, statistics: []})
        })*/
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.retrieveData()
      }
    }

    componentDidMount() {
      console.log('DID MOUNT')
      this.retrieveData()
    }

    render() {
      return (
        <>
          <LoadingIndicator loading={this.state.loading}/>
          <WrappedComponent { ...this.state} {...this.props}/>
        </>
      )
    }
}