import React from 'react'

const chipList = [
  { imagePath: './images/chips/GPT_SM1.jpg', name: 'G.P.T 01' },
  { imagePath: './images/chips/GPT_SM2.jpg', name: 'G.P.T 02 ' },

  { imagePath: './images/chips/GEMPLUS_GEM1.jpg', name: 'GEMPLUS 01 ' },
  { imagePath: './images/chips/GEMPLUS_GEM2.jpg', name: 'GEMPLUS 02 ' },
  { imagePath: './images/chips/GEMPLUS_GEM3.jpg', name: 'GEMPLUS 03 ' },

  { imagePath: './images/chips/ORGA_SM3.jpg', name: 'ORGA 01 ' },
  { imagePath: './images/chips/ORGA_SM4.jpg', name: 'ORGA 02 ' },
  { imagePath: './images/chips/ORGA_SM5.jpg', name: 'ORGA 03 ' },
  { imagePath: './images/chips/ORGA_SM6.jpg', name: 'ORGA 04 ' },

  { imagePath: './images/chips/SCHLUMBERGER_SC7.jpg', name: 'SCHLUMBERGER 04 ' },
  { imagePath: './images/chips/SCHLUMBERGER_SC8.jpg', name: 'SCHLUMBERGER 02 ' },
  { imagePath: './images/chips/SCHLUMBERGER_SC9.jpg', name: 'SCHLUMBERGER 03 ' },
  { imagePath: './images/chips/SCHLUMBERGER_SO3.jpg', name: 'SCHLUMBERGER 04 ' },

  { imagePath: './images/chips/INTRACOM_GEM2.jpg', name: 'INTRACOM ' },

  { imagePath: './images/chips/PERIVALLON_SC8.jpg', name: 'ENVIRONMENT 01 ' },
  { imagePath: './images/chips/PERIVALLON_SC9.jpg', name: 'ENVIRONMENT 02 ' },
  { imagePath: './images/chips/PERIVALLON_SM4.jpg', name: 'ENVIRONMENT 03 ' },
  { imagePath: './images/chips/PERIVALLON_SO3.jpg', name: 'ENVIRONMENT 04 ' },

  { imagePath: './images/chips/SIEMENS.jpg', name: 'SIEMENS ' },

  { imagePath: './images/chips/NAMAKO_01.jpg', name: 'NAMAKO 01' }
]

export default () => {
  return (
    <>
      <div className="row col-6 offset-3">
        {
          chipList.map(function(chip, index){
            return (
              <div className="col-md-3" key={index}>
                <img src={chip.imagePath} className="chip" alt="Chip"/>
                <div>{chip.name}</div>
              </div>
            )
        })}
      </div>
      <div className="container centered" style={{ width: 600 }}>
        <label style={{ color: '#0066FF' }} className="font-weight-bold">Η Ελληνική τηλεκάρτα</label>
        <br/>
          <p style={{ textAlign: 'justify' }}>
            Στο τέλος του 1987, ο ΟΤΕ αποφάσισε
            να κάνει δοκιμές και να
            τυπώσει τις πρώτες δοκιμαστικές τηλεκάρτες.
            Εως το 1992 τυπώθηκαν και δοκιμάστηκαν πολλές δοκιμαστικές
            αλλά και μαγνητικές κάρτες μέχρι να καταλήξουν στη σημερινη
            μορφή της τηλεκάρτας.
            Τον Σεπτέμβριο του 1992 κυκλοφόρισε η πρώτη επίσημη τηλεκάρτα
            η οποία και παρουσιάστηκε στην διεθνή έκθεση της θεσσαλονίκης.
            Ο ΟΤΕ για τον λόγο αυτο είχε τοποθετήσει μέσα και έξω από τον χώρο
            της έκθεσης πολλά καρτοτηλέφωνα και χάρισε πολλές τηλεκάρτες
            με θέμα τον Ηλιο της Βεργίνας.
          </p>
          <br/>
            <label style={{ color: '#0066FF' }} className="font-weight-bold">Οι οψεις της τηλεκάρτας</label><br/>
            Στις δύο οψεις της τηλεκάρτας υπάρχουν τα εξης στοιχεία:
            <ol style={{ textAlign: 'left' }}>
              <li>Το θέμα</li>
              <li>Ο κωδικός με τη αρίθμηση</li>
              <li>Το Chip</li>
              <li>Οι μονάδες ή η αξία της τηλεκάρτας</li>
              <li>Λογότυπο του ΟΤΕ</li>
              <li>Ημερομηνία παραγωγής και ημερομηνία λήξης.</li>
            </ol>

            <label style={{ color: '#0066FF' }} className="font-weight-bold">Με δυο λόγια</label><br/>
            <p style={{ textAlign: 'justify' }}>
              Ο σκοπός της σελίδας είναι η ενημέρωση των νεων συλλεκτών
              καθώς επίσης και η εξειδικευμένη παρουσίαση (όσο γίνεται) με πολλά στοιχεία που υπάρχουν
              για τις τηλεκάρτες όπως σφάλματα ,ανάποδες εκτυπώσεις ,
              πλάγιες γραφές ,σφήνες ,ποικιλίες των γραμματοσειρών του εκτυπωτή,
              διαγράμμιση ή όχι του ψηφίου μηδέν, και άλλα.
              Εαν βρείτε λάθη η παραλήψεις στις ημερομηνίες η στη ποσότητα
              η κάποια άλλα ,άλλωστε ουδείς τέλειος ,<strong>μη διστάσετε να επικοινωνησετε μαζί μου για να τα
              διορθώσω</strong>.
              Η σειρά παρουσίασης των καρτών βασίζεται στην ημερομηνία έκδοσής τους,
              και στην επίσημη αρίθμηση και κωδικοποίηση του ΟΤΕ - <strong>Χ, D, Μ, Υ ,S</strong>
              και τέλος στους σειριακούς αριθμούς των Chips.
              Η αξία της κάθε τηλεκάρτας είναι υποκειμενική για τον αγοραστή
              και για τον πωλητή και καθορίζετε πιστεύω από το θέμα που απεικονίζει,
              από το τιράζ, από την εκτύπωση, από το αν είναι γεμάτη ή άδεια,
              αν είναι μέσα στην ζελατίνα, τα διάφορα σφάλματα στο Chip, τίς ποικιλίες &lt;
              των γραμματοσειρών του εκτυπωτή, τη διαγράμμιση ή όχι του ψηφίου μηδέν,
              και γενικότερα από κάθε τι που την καθιστά σπάνια και δυσεύρετη.
            </p>
      </div>
    </>
   )
}