import React, { Fragment, PureComponent } from 'react'
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem
} from 'reactstrap'
import DataComponent from '../hoc/DataComponent'
import { getImagePath } from '../utils/utils.js'
import { PhonecardsContext } from '../contexts/PhonecardsContext.tsx'
import { baseUrl } from '../common/constants'
import Header from './Header'

class LatestCardsCarousel extends PureComponent {

  constructor(props) {
    super(props)
    console.log('home props: ', this.props)
    this.state = { activeIndex: 0 }
  }

  onExiting = () => {
    this.animating = true
  }

  onExited = () => {
    this.animating = false
  }

  next = () => {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === this.props.data.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? this.props.data.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex = (newIndex) => {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    // Το component CarouselIndicators ψάχνει το src σαν key.
    const items = this.props.data.map(x => ({
      ...x,
      src: x.code
    }))

    const slides = this.props.data.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.code}
        >
          <img src={getImagePath(item, 'c')} alt={item.descrGR} width="400" height="250"/>
          <img src={getImagePath(item, 'd')} alt={item.descrGR} width="400" height="250"/>
          <CarouselCaption captionText={item.descrGR} captionHeader="" className="carousel-caption-bordered" />
        </CarouselItem>
      )
    })

    return (
      <PhonecardsContext.Consumer>
        {({ language }) => (
            <div style={{ width: 800, margin: 'auto', marginTop: 30 }}>
              <Header language={ language } title="title.latest.releases"/>

              <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
                interval={4000}
              >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
              </Carousel>
            </div>
        )}
      </PhonecardsContext.Consumer>
    )
  }
}

export default DataComponent(LatestCardsCarousel, baseUrl + '/newcards')
