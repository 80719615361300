import React, { PureComponent } from 'react'
import axios from 'axios'
import { PhonecardsContext } from '../contexts/PhonecardsContext'
import Header from '../components/Header'

export default class ErrorsContainer extends PureComponent {

  componentDidMount() {
    axios.get('http://www.phonecards.gr/api/errors')
      .then((response) => {
        console.log(response.data)
        this.setState({ data: response.data, loading: false })
      })
      .catch(error => {
        console.log('----------------------------------------------------', error)
        this.setState({ data: [], loading: false })
      })
  }

  render() {
    return (
      <PhonecardsContext.Consumer>
        {({ language }: {language: string}) => (
            <Header language={language} title='title.errors'/>
        )}
      </PhonecardsContext.Consumer>
    )
  }

}
