import React, { PureComponent } from 'react'
import SuperOffersDeck from './SuperOffersDeck'
import LatestCardsCarousel from './LatestCardsCarousel'
import { withRouter } from 'react-router-dom'
import LatestNewsPanel from './LatestNewsPanel'

interface IProps {
  match: any
  location: string
}

class Home extends PureComponent<IProps> {

  constructor(props: IProps) {
    super(props)
    console.log('home props: ', this.props)
    this.state = { activeIndex: 0 }
  }

  render() {

    return (
      <>
        <LatestCardsCarousel match={this.props.match} location={this.props.location} />
        <LatestNewsPanel match={this.props.match} location={this.props.location}/>
        <SuperOffersDeck match={{ url: '/superOffers' }} location={this.props.location}/>
      </>
    )
  }
}

// @ts-ignore
export default withRouter(Home)
