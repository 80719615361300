import React, { PureComponent } from 'react'

import DataComponent from '../hoc/DataComponent'
import { FormattedMessage } from 'react-intl'
import { PhonecardsContext } from '../contexts/PhonecardsContext.tsx'
import { withRouter } from 'react-router-dom'
import { filterResult, formatAmount, formatQuantityNumber, getImagePath } from '../utils/utils.js'
import { Pagination, PaginationItem, PaginationLink, UncontrolledTooltip } from 'reactstrap'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CardList extends PureComponent {

  constructor(props) {
    super(props)
    this.state = { currentPage: 0, pageSize: 10 }
  }

  handleClick(e, index) {
    e.preventDefault()
    this.setState({ ...this.state, currentPage: index })
    console.log(index)
  }

  filterResults = event => {
    this.setState({ currentPage: 0, searchKeyword: event.target.value })
  }

  changePageSize = event => {
    this.setState({ ...this.state, pageSize: event.target.value })
  }

  generateOptions = () => {
    return (
      <select className="custom-select" id="inputGroupSelect01" onChange={this.changePageSize}>
        {
          [10, 20, 50, 100, 200, 500, 1000].map(pageSize => {
            return <option key={pageSize} value={pageSize} selected={pageSize === this.state.pageSize ? 'selected' : ''}>{pageSize}</option>
          })
        }
      </select>
    )
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ ...this.state, currentPage: 0, pageSize: 10 })
    }
  }

  convertImgToBase64URL = (url, callback, outputFormat) => {
    var canvas = document.createElement('CANVAS'),
      ctx = canvas.getContext('2d'),
      img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = function() {
      var dataURL
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0)
      dataURL = canvas.toDataURL(outputFormat)
      callback(dataURL)
      canvas = null
    }
    img.src = url
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

/*  start = async () => {
    await this.asyncForEach([1, 2, 3], async (num) => {
      await waitFor(50);
      console.log(num);
    });
    console.log('Done');
  }*/

  getBase64Image = (imgUrl, callback) => {

    var img = new Image()

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function() {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      var dataURL = canvas.toDataURL('image/png')
        //dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      callback(dataURL) // the base64 string
    }

    // set attributes and src
    img.setAttribute('crossOrigin', 'anonymous') //
    img.src = imgUrl
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute('crossOrigin', 'anonymous')

      img.onload = () => {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        var dataURL = canvas.toDataURL('image/png')
        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }


  render() {
    //,this.props, this.props.location.pathname);

    const { currentPage, pageSize } = this.state

    console.log('CardList render', currentPage, pageSize)
    // Το component CarouselIndicators ψάχνει το src σαν key.
/*    const items = this.props.data.map(x => ({
      ...x,
      src: x.code
    }))*/
    //console.log('SEARCH KEYWORD', this.state.searchKeyword)

    const filteredData = this.props.data.filter(card => {return this.state.searchKeyword ? filterResult(card, this.state.searchKeyword) : card})

    const totalPages = Math.ceil(filteredData.length / pageSize)

    //[...Array(totalPages)].map((_,i) => i)

    return (
      <PhonecardsContext.Consumer>
        {({ language, imageSize, changeImageSize, addItemToCart }) => (
          <div className={this.props.visible ? '' : 'd-none'}>
            <div className="d-flex justify-content-center no-print">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink onClick={e => this.handleClick(e, currentPage - 1)} previous href="#"/>
                </PaginationItem>

                {[...Array(totalPages).keys()].filter(index => {return currentPage === 0 ? index < currentPage + 10 : index >= currentPage - 4 && index <= currentPage + 4}).map((page, i) =>
                  <PaginationItem active={page === currentPage} key={page} style={{ width: 42, textAlign: 'center' }}>
                    <PaginationLink onClick={e => this.handleClick(e, page)} href="#">
                      {page + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem disabled={currentPage >= this.pagesCount - 1}>
                  <PaginationLink
                    onClick={e => this.handleClick(e, currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>
              </Pagination>
            </div>

            <div className="row mb-3 float-right no-print">
              <button className="btn btn-primary" style={{ width: 60 }} onClick={changeImageSize}>^</button>
             {/* <button className="btn btn-default" onClick={this.downloadPdf}>pdf</button>*/}
              <div className="col-3 form-group mr-2">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <label className="input-group-text" htmlFor="searchTextField"><FormattedMessage id="text.search"/></label>
                  </div>
                  <input type="text" id="searchTextField" className="form-control ng-pristine ng-valid" onChange={this.filterResults}/>
                </div>
              </div>
              <div className="col-2 form-group">
                <div className="input-group float-right" style={{ width: 200 }}>
                  <div className="input-group-prepend">
                    <label className="input-group-text" htmlFor="inputGroupSelect01"><FormattedMessage id="text.results"/></label>
                  </div>
{/*                    {[10, 20, 50, 100, 200, 500].map(function (pageSize) {
                      return <option key={pageSize} value={pageSize}
                                    >{pageSize}</option>
                    })
                    }*/}
                    {this.generateOptions()}
                </div>
              </div>
            </div>

            <table className="table table-sm main-table">
              <thead className="thead-dark">
              <tr>
                <th style={{ width: 140 }}><FormattedMessage id="text.front.side"/></th>
                <th style={{ width: 140 }}><FormattedMessage id="text.back.side"/></th>
                <th style={{ width: 80 }}><FormattedMessage id="text.code"/></th>
                <th><FormattedMessage id="text.description"/></th>
                <th><span className="d-none d-lg-block"><FormattedMessage id="text.units"/></span></th>
                <th><FormattedMessage id="text.month.year"/></th>
                <th style={{ textAlign: 'right' }}>
                  <span style={{ float: 'left' }}><FormattedMessage id="text.numbering"/>/<FormattedMessage id="text.quantity"/></span>
                  <span style={{ float: 'right' }}>
                    <FormattedMessage id="text.price"/>&nbsp;
                    (<FormattedMessage id="text.used"/> → <FontAwesomeIcon className="add-to-cart-used" style={{ color: '#688bef' }} icon={faShoppingCart} />)
                    (<FormattedMessage id="text.sealed"/> → <FontAwesomeIcon className="add-to-cart-mint" icon={faShoppingCart} />)
                  </span>
                </th>
              </tr>
              </thead>
              <tbody>
              {
                filteredData.filter((card, index) => index >= currentPage * pageSize && index < (currentPage * pageSize) + eval(pageSize)).map(function(card, index) {
                  return (
                    <tr key={'card-' + card.code + index}>
                      <td>
                        <img className={'img-card-' + imageSize} src={getImagePath(card, 'c')} alt="Front Side" id={'card-' + card.code + index}/>
                        <UncontrolledTooltip placement="right" target={'card-' + card.code + index} delay={{ show: 0, hide: 0 }}>
                          <img src={getImagePath(card, 'c')} alt="front side"/>
                        </UncontrolledTooltip>
                      </td>
                      <td>
                        <img className={'img-card-' + imageSize} src={getImagePath(card, 'd')} alt="Back Side" id={'card-' + card.code + index + 'b'}/>
                        <UncontrolledTooltip placement="right" target={'card-' + card.code + index + 'b'} delay={{ show: 0, hide: 0 }}>
                          <img src={getImagePath(card, 'd')} alt="back side"/>
                        </UncontrolledTooltip>
                        </td>
                      <td>{card.code}</td>
                      <td>{language === 'el' ? card.descrGR : card.descr}</td>
                      <td><span className="d-none d-lg-block">{card.units}</span></td>
                      <td>{(card.m === 0 ? '' : ('0' + card.m).slice(-2) + '/') + (card.y === 0 ? '' : card.y)}<span style={{ color: '#8c39cc', marginLeft: 5, float: 'right' }}>{formatQuantityNumber(card.qty)}</span></td>
                      <td className="to-right" style={{ paddingTop: 0 }}>
                          {card.serialNumbers.map(function(serialNumber, index) {
                            return (
                              <div className="row" key={card.code + index + serialNumber.serial + serialNumber.start}>
                                {
                                  serialNumber.start === '-1' &&
                                  <div className="col-sm-4">
                                    <span style={{ padding: 0, textAlign: 'right', color: 'red', fontWeight: 'bold', textDecoration: 'underline', fontSize: '1.1em' }} className={index === 0 ? 'no-border-top' : ''}>
                                      {serialNumber.serial}
                                    </span>
                                  </div>
                                }
                                {
                                  serialNumber.serial.indexOf('SUM') >= 0 &&
                                  <div className="col-sm-4 col-print-5">
                                    <span style={{ padding: 0, textAlign: 'right', color: '#a36fff', fontWeight: 'bold', textDecoration: 'underline', fontSize: '1.1em' }} className={index === 0 ? 'no-border-top' : ''}>
                                      {serialNumber.serial.substring(4)}&nbsp;
                                      {serialNumber.start && <span className="font-italic text-secondary-override">{serialNumber.start.padStart(6, '0')} - {serialNumber.end.padStart(6, '0')}</span>}
                                    </span>
                                  </div>
                                }
                                {
                                  serialNumber.start !== '-1' && serialNumber.serial.indexOf('SUM') < 0 &&
                                  <div className="col-sm-4 col-print-5">
                                    <span className=
                                            {(serialNumber.comments === 'Χοντρή γραφή'
                                              ?
                                              'gpt-font1-bold'
                                              :
                                              (serialNumber.comments === 'letraset'
                                                ? 'gpt-letraset'
                                                : (serialNumber.serial.indexOf('Ø') >= 0 ? 'gpt-lined' : 'gpt-font1')
                                              )
                                            )
                                            }
                                          style={{ fontWeight: serialNumber.comments === 'Χοντρή γραφή' ? 'bold' : 'normal' }}>
                                      {serialNumber.serial.replace(/O/g, '0').replace(/Ø/g, 0)}
                                    </span>
                                    {
                                      serialNumber.start
                                        ?
                                        <span className="font-italic text-secondary-override numbering">{serialNumber.start.padStart(6, '0')} - {serialNumber.end.padStart(6, '0')}</span>
                                        :
                                        <span className="font-italic text-secondary-override numbering"/>
                                    }

                                    {
                                      serialNumber.chipImagePath
                                        ?
                                        <img src={serialNumber.chipImagePath.replace('\.', '')} width="45" height="31" alt="Chip"/>
                                        :
                                        null
                                    }
                                  </div>
                                }

                                <div className="col-sm-2">
                                  {
                                    serialNumber.start === '-1' && (
                                      <div style={{ width: 80, color: 'red', fontWeight: 'bold', textDecoration: 'underline', fontSize: '1.1em' }}
                                           className={(!serialNumber.chipImagePath ? 'td-normal' : 'td-with-image') + (index === 0 ? ' no-border-top' : '')}
                                      >
                                        {formatQuantityNumber(serialNumber.end)}
                                      </div>
                                    )
                                  }
                                  {
                                    serialNumber.end && serialNumber.end - serialNumber.start === 0 && (
                                      <div style={{ width: 80, color: '#558ecc' }}
                                           className={(!serialNumber.chipImagePath ? 'td-normal' : 'td-with-image') + (index === 0 ? ' no-border-top' : '')}
                                      >
                                        {serialNumber.serial ? formatQuantityNumber(serialNumber.qty) : formatQuantityNumber(card.qty)}
                                      </div>
                                    )
                                  }
                                  {
                                    serialNumber.qty > 0 && (
                                      <div className={(!serialNumber.chipImagePath ? 'td-normal quantity' : 'td-with-image quantity') + (index === 0 ? ' no-border-top' : '')}>
                                        ~ {formatQuantityNumber(serialNumber.qty)}
                                      </div>
                                    )
                                  }
                                  {
                                    !serialNumber.qty && serialNumber.start !== '-1' && serialNumber.end - serialNumber.start > 0 && (
                                      <div className={(!serialNumber.chipImagePath ? 'td-normal quantity' : 'td-with-image quantity') + (index === 0 ? ' no-border-top' : '')}>
                                        {formatQuantityNumber(serialNumber.end - serialNumber.start + 1)}
                                      </div>
                                    )
                                  }
                                </div>

                                <div className=" col-sm-2 col-print-4 font-italic text-secondary-override" style={{ color: '#8c39cc', textAlign: 'left', fontSize: '0.9em' }}>
                                  {serialNumber.comments}
                                </div>
                                <div className="col-sm-2 no-print">
                                  {
                                    serialNumber.price && serialNumber.price !== 0
                                      ?
                                      <a className="cursor-pointer" onClick={() => addItemToCart({ card: card, serialNumberInfo: serialNumber, isUsed: true })}>
                                        {serialNumber.offerPrice ? <s className="disabled-price">{formatAmount(serialNumber.price)}</s> : ''}
                                        {(serialNumber.offerPrice ? formatAmount(serialNumber.offerPrice) : formatAmount(serialNumber.price))}
                                        <FontAwesomeIcon className="add-to-cart-used" style={{ color: '#688bef' }} icon={faShoppingCart} />
                                      </a>
                                      :
                                      ''
                                  }
                                </div>
                                <div className="col-sm-2 no-print">
                                  {
                                    serialNumber.sealedPrice && serialNumber.sealedPrice !== 0
                                      ?
                                      <a className="cursor-pointer" onClick={() => addItemToCart({ card: card, serialNumberInfo: serialNumber, isUsed: false })}>
                                        {formatAmount(serialNumber.sealedPrice)}
                                        <FontAwesomeIcon className="add-to-cart-mint" icon={faShoppingCart} />
                                      </a>
                                      :
                                      ''
                                  }
                                </div>
                              </div>
                            )
                          })}
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>

            <div className="d-flex justify-content-center no-print">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink
                      onClick={e => this.handleClick(e, currentPage - 1)}
                      previous
                      href="#"
                  />
                </PaginationItem>

                {[...Array(totalPages).keys()].filter(index => {return currentPage === 0 ? index < currentPage + 10 : index >= currentPage - 4 && index <= currentPage + 4}).map((page, i) =>
                    <PaginationItem active={page === currentPage} key={page} style={{ width: 42, textAlign: 'center' }}>
                      <PaginationLink onClick={e => this.handleClick(e, page)} href="#">
                        {page + 1}
                      </PaginationLink>
                    </PaginationItem>
                )}

                <PaginationItem disabled={currentPage >= this.pagesCount - 1}>
                  <PaginationLink
                      onClick={e => this.handleClick(e, currentPage + 1)}
                      next
                      href="#"
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        )}
      </PhonecardsContext.Consumer>
    )
  }
}

export default withRouter(DataComponent(CardList, ''))
