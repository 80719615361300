import React, { Component } from 'react'
import './App.css'

import Navbar from './components/Navbar'
import MainMenu from './components/MainMenu'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import History from './components/History'
import CardContainer from './containers/CardContainer'
import ShoppingCartContainer from './containers/ShoppingCartContainer'
import Flyers from './components/Flyers'
import UpdateInfoContainer from './containers/UpdateInfoContainer'
import ErrorsContainer from './containers/ErrorsContainer'

class App extends Component {

  render() {
    return (
      <>
        <Router>
          <>
            <Navbar/>
            <div className="jumbotron bg-light" style={{ marginTop: 70, marginBottom: 60 }}>
              <div className="centered menu-container no-print">
                <MainMenu/>
              </div>
              {/*<Route exact path="/" render={()=><Home url="http://react.phonecards.gr/api/newcards"/>}/>*/}
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/flyers" component={Flyers}/>
                <Route exact path="/cards-history" component={History}/>
                {/*<Route path="/cards/*" render={()=><CardList {...this.props}/>}/>*/}
                <Route exact path="/cards/errors" component={ErrorsContainer}/>
                <Route path="/cards/:category/:year?/:month?" component={CardContainer}/>
                <Route exact path="/shopping-cart" component={ShoppingCartContainer}/>
                <Route exact path="/update-cards/update-info" component={UpdateInfoContainer}/>
              </Switch>
            </div>
          </>
        </Router>
        <footer className="footer bg-dark no-print">
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="p-1">
                <a href="https://www.facebook.com/%CE%95%CE%BB%CE%BB%CE%B7%CE%BD%CE%B9%CE%BA%CE%AD%CF%82-%CE%A4%CE%B7%CE%BB%CE%B5%CE%BA%CE%AC%CF%81%CF%84%CE%B5%CF%82-%CE%A7%CF%81%CE%BF%CE%BD%CE%BF%CE%BA%CE%AC%CF%81%CF%84%CE%B5%CF%82-107047956031187/" target="_blank" rel="noopener noreferrer"><img src="./images/f.png" alt="Facebook Page" /></a>
                <span>Font made from <a href="http://www.onlinewebfonts.com">Online Web Fonts</a> is licensed by CC BY 3.0</span>
              </div>
              <div className="p-1">
                <span className="text-muted"><span className="text-center">www.phonecards.gr version 1.0.3 (powered by react 18.2)</span></span>
              </div>
              <div className="p-1">
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default App
