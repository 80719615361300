import React from 'react'
import DataComponent from '../hoc/DataComponent'
import { Link, withRouter } from 'react-router-dom'
import ICalendarInfo from '../interfaces/ICalendarInfo'

interface IProps {
    visible: boolean
    category: string
    data: ICalendarInfo[]
}

const CardCalendar: React.FC<IProps> = props => {
  return (
      <table className={'table table-striped table-hover table-sm table-compact ' + (props.visible ? '' : 'd-none')}>
        <thead className="thead-dark">
        <tr>
          <th className="text-center">Έτος/Μήνας</th>
          <th className="text-center">1</th>
          <th className="text-center">2</th>
          <th className="text-center">3</th>
          <th className="text-center">4</th>
          <th className="text-center">5</th>
          <th className="text-center">6</th>
          <th className="text-center">7</th>
          <th className="text-center">8</th>
          <th className="text-center">9</th>
          <th className="text-center">10</th>
          <th className="text-center">11</th>
          <th className="text-center">12</th>
          <th className="text-center">Σύνολο</th>
        </tr>
        </thead>
        <tbody>
        {
            props.data && props.data.map(function(calendarInfo, index: number) {
              return (
                <tr key={index}>
                  <td className="td-bordered text-center">
                    <Link className="anchor font-weight-bold" to={'/cards/' + props.category + '/' + calendarInfo.year}>{calendarInfo.year}</Link>
                  </td>
                  {
                      Object.keys(calendarInfo.months).map(function(key, index) {
                        return (
                          <td key={index} className="centered td-bordered" style={{ width: 35 }}>
                            {calendarInfo.months[key] ? <Link className="anchor" to={'/cards/' + props.category + '/' + calendarInfo.year + '/' + key.replace('m', '')}>{calendarInfo.months[key]}</Link> : null}
                          </td>
                          )
                      })
                  }
                </tr>
              )})
        }
        </tbody>
      </table>
  )
}

export default withRouter(DataComponent(CardCalendar, '', true))