//export const baseUrl = "http://react.phonecards.gr/api"
//export const baseUrl = "http://www.phonecards.gr/api"
export const baseUrl = '/api'

export const isPHP = false

export const linksMap: {[key: string]: {url: string, headerId: string}} = {}

linksMap['/']                   = { url: baseUrl + '/newcards',                headerId: 'title.latest.releases' }
linksMap['/cards/phonecards']   = { url: baseUrl + '/phonecards/phonecards',   headerId: 'title.phonecards' }
linksMap['/cards/collectibles'] = { url: baseUrl + '/phonecards/collectibles', headerId: 'title.collectibles' }
linksMap['/cards/cyprus']       = { url: baseUrl + '/phonecards/cyprus',       headerId: 'title.cyprus' }
linksMap['/cards/chronocards']  = { url: baseUrl + '/phonecards/chronocards',  headerId: 'title.chronocards' }
linksMap['/cards/ex']           = { url: baseUrl + '/phonecards/ex',           headerId: 'title.exhibition' }
linksMap['/cards/str']          = { url: baseUrl + '/phonecards/str',          headerId: 'title.starbucks' }
linksMap['/cards/dl']           = { url: baseUrl + '/phonecards/dl',           headerId: 'title.dl' }
linksMap['/cards/vf']           = { url: baseUrl + '/phonecards/vf',           headerId: 'title.vf' }
linksMap['/cards/otn']          = { url: baseUrl + '/phonecards/otn',          headerId: 'title.otn' }
linksMap['/cards/cos']          = { url: baseUrl + '/phonecards/cos',          headerId: 'title.cos' }
linksMap['/cards/pan']          = { url: baseUrl + '/phonecards/pan',          headerId: 'title.pan' }
linksMap['/cards/tel']          = { url: baseUrl + '/phonecards/tel',          headerId: 'title.tel' }
linksMap['/cards/offers']       = { url: baseUrl + '/phonecards/offers',       headerId: 'title.offers' }
linksMap['/cards/mint']         = { url: baseUrl + '/phonecards/mint',         headerId: 'title.mint' }
linksMap['/shopping-cart']      = { url: baseUrl + '/shopping-cart',           headerId: 'title.shopping.cart' }
linksMap['/superOffers']        = { url: baseUrl + '/phonecards/superOffers',  headerId: 'title.shopping.cart' }
linksMap['/news']               = { url: baseUrl + '/news',                    headerId: 'title.latest.news' }