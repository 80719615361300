import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'

import { PhonecardsProvider } from './contexts/PhonecardsContext'
import { PhonecardsContext } from './contexts/PhonecardsContext'

import messages_el from './translations/el.json'
import messages_en from './translations/en.json'

const messages: {[index: string]:any} = {
  'el': messages_el,
  'en': messages_en
}

// const language = navigator.language.split(/[-_]/)[0]
const container = document.getElementById('root')
// @ts-ignore
const root = createRoot(container)

root.render(
  <PhonecardsProvider>
    <PhonecardsContext.Consumer>
        {({ language, changeLocale }) => (
          <IntlProvider locale={ language } messages={messages[language ? language : 'el']}>
            <App />
          </IntlProvider>
        )}
      </PhonecardsContext.Consumer>
  </PhonecardsProvider>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
