import React from 'react'
import DataComponent from '../hoc/DataComponent'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { formatAmount, getImagePath } from '../utils/utils.js'
import { PhonecardsContext } from '../contexts/PhonecardsContext'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ICartItem from '../interfaces/ICartItem'

interface IProps {
  items: ICartItem[]
}

const ShoppingCart: React.FC<IProps> = props => {
  return (
    <PhonecardsContext.Consumer>
      {({ language, changeQuantity } : {language: string, changeQuantity(index: number, add: boolean): void}) => (
        props.items &&
        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th className="unselectable"><FormattedMessage id="text.front.side"/></th>
              <th className="unselectable"><FormattedMessage id="text.back.side"/></th>
              <th className="unselectable"><FormattedMessage id="text.code"/></th>
              <th className="unselectable"><FormattedMessage id="text.description"/></th>
              <th className="unselectable"><FormattedMessage id="text.units"/></th>
              <th className="unselectable"><FormattedMessage id="text.month.year"/></th>
              <th className="text-right unselectable"><FormattedMessage id="text.numbering"/></th>
              <th className="text-right unselectable"><FormattedMessage id="text.price"/></th>
              <th className="text-right unselectable"><FormattedMessage id="text.sealed.price"/></th>
              <th className="text-right unselectable">Ποσότητα</th>
              <th className="text-right unselectable">Σύνολο</th>
              <th className="unselectable"/>
              <th className="unselectable"/>
            </tr>
          </thead>
          <tbody>
          {
            props.items.map(function(card: ICartItem, index: number) {
              return (
                <tr key={card.code + index}>
                  <td className="unselectable"><img className="img-card-normal" src={getImagePath(card, 'c')} alt="Front Side"/></td>
                  <td className="unselectable"><img className="img-card-normal" src={getImagePath(card, 'd')} alt="Back Side"/></td>
                  <td className="unselectable">{card.code}</td>
                  <td className="unselectable">{language === 'el' ? card.descrGR : card.descr}</td>
                  <td className="unselectable">{card.units}</td>
                  <td className="unselectable">{('0' + card.m).slice(-2)}/{card.y}</td>
                  <td className="to-right unselectable">
                    {card.serialNumberInfo.serial}&nbsp;
                    {card.serialNumberInfo.start && card.serialNumberInfo.end  ? <span className="font-italic text-secondary-override">{card.serialNumberInfo.start.padStart(6, '0')} - {card.serialNumberInfo.end.padStart(6, '0')}</span> : null}
                  </td>
                  <td className="text-right unselectable">{card.count > 0 && (card.offerPrice ? formatAmount(card.offerPrice) : formatAmount(card.price))}</td>
                  <td className="text-right unselectable">{card.sealedPrice && card.sealedPrice > 0 && formatAmount(card.sealedPrice)}</td>
                  <td className="text-right unselectable">{card.count > 0 ? card.count : ''} {card.countMint > 0 ? card.countMint : ''}</td>
                  <td className="text-right unselectable">
                    {card.count > 0 && <span>{formatAmount(card.count * (card.offerPrice > 0 ? card.offerPrice : (card.price ? card.price : 0)))}</span>}
                    {card.countMint > 0 && <span>{formatAmount(card.countMint * card.sealedPrice)}</span>}
                  </td>
                  <td><a onClick={() => changeQuantity(index, true)}><FontAwesomeIcon className="add-to-cart-used" style={{ color: '#688bef' }} icon={faPlus} /></a></td>
                  <td><a onClick={() => changeQuantity(index, false)}><FontAwesomeIcon className="add-to-cart-used" style={{ color: '#688bef' }} icon={faMinus} /></a></td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      )}
    </PhonecardsContext.Consumer>
  )
}

export default withRouter(DataComponent(ShoppingCart, '', true))