import React from 'react'
import axios from 'axios'
import IContextProps from '../interfaces/IContextProps'
import ICartItem from '../interfaces/ICartItem'
import ITransientCartItem from '../interfaces/ITransientCartItem'
import IPhonecardsProviderState from '../interfaces/IPhonecardsProviderState'

// Context is made up of two things
// Provider - Single as close to top level as possible
// Consumer - Multiple have multiple consumers
// The defaultValue argument is only used when a component does not have a matching Provider above it in the tree.
// This can be helpful for testing components in isolation without wrapping them.
// Note: passing undefined as a Provider value does not cause consuming components to use defaultValue.
export const PhonecardsContext = React.createContext<IPhonecardsProviderState>(
  {
    language: 'el',
    orderInfo: { items: new Array<ICartItem>(), name: '', address: '', city: '', postalCode: '', phone: '', email: '', comments: '', paymentType: '' },
    sendEmail(event: any): void {},
    changeValue(event: any, field: string): void {},
    changeQuantity(index: number, add: boolean): void {}
  })

// Clock has no properties, but the current state is of type ClockState
// The generic parameters in the Component typing allow to pass props
// and state. Since we don't have props, we pass an empty object.
export class PhonecardsProvider extends React.Component<{ children: any }, IPhonecardsProviderState> {

  changeImageSize = () => {
    if (this.state.imageSize === 'normal') {
      this.setState({ ...this.state, imageSize: 'medium' })
    } else if (this.state.imageSize  === 'medium') {
      this.setState({ ...this.state, imageSize: 'big' })
    } else if (this.state.imageSize  === 'big') {
      this.setState({ ...this.state, imageSize: 'huge' })
    } else {
      this.setState({ ...this.state, imageSize: 'normal' })
    }
  }

  calcTotal = () => {
    let total = 0

    this.state.orderInfo.items.forEach(function (item: ICartItem) {
      if (item.offerPrice) {
        total += item.offerPrice * item.count
      } else if (item.price) {
        total += item.price * item.count
      }
      if (item.sealedPrice) {
        total += item.sealedPrice * item.countMint
      }
    })

    return total
  }

  changeQuantity = (index: number, add: boolean) => {
    const newItems: ICartItem[] = [...this.state.orderInfo.items]
    if (add) {
      newItems[index].isUsed ? newItems[index].count++ : newItems[index].countMint++
    } else {
      if (newItems[index].isUsed) {
        if (newItems[index].count === 1) {
          newItems.splice(index, 1)
        } else {
          newItems[index].count--
        }
      } else {
        if (newItems[index].countMint === 1) {
          newItems.splice(index, 1)
        } else {
          newItems[index].countMint--
        }
      }
    }

    this.setState({ ...this.state, orderInfo: { items: newItems } })
  }

  sendEmail = (event: any) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    console.log('=========', this.state.orderInfo)

    axios.post('/api/SendEmail', this.state.orderInfo)
      .then((response) => {
      console.log(response)
      alert('Η παραγγελία σας εστάλη')
    })
  }

  //You have to define what kind of index type the object has. In your case it is a string based index.
  // const myObj: {[index: string]:any} = {}

  changeValue = (event: any, field: string) => {
    //let clone1: {[index: string]: IOrderInfo} = Object.assign({}, this.state.orderInfo)
    //clone1 = Object.assign({}, this.state.orderInfo)
    const clone: {[index: string]: any} = Object.assign({}, this.state.orderInfo)
    clone[field] = event.target.value
    // @ts-ignore
    this.setState({ ...this.state, orderInfo: clone })
  }

  addItemToCart = (cardInfo: ITransientCartItem) => {
    console.log('===sss======', cardInfo, this.state.orderInfo)
    const clone = Object.assign({}, this.state.orderInfo)

    let repeat = false
    //let cartCard: Partial<ICartItem> = {}
    const cartCard: ICartItem = {
      count: 0,
      countMint: 0,
      tp: 0,
      code: '',
      units: '',
      m: 0,
      y: 0,
      qty: 0,
      descr: '',
      descrGR: '',
      serialNumberInfo: { sealedPrice: 0, offerPrice: 0 },
      sealedPrice: 0,
      offerPrice: 0
    }

    cartCard.tp = cardInfo.card.tp
    cartCard.code = cardInfo.card.code
    cartCard.descrGR = cardInfo.card.descrGR
    cartCard.units = cardInfo.card.units
    cartCard.m = cardInfo.card.m
    cartCard.y = cardInfo.card.y
    cartCard.qty = cardInfo.card.qty
    cartCard.price = cardInfo.serialNumberInfo.price
    cartCard.offerPrice = cardInfo.serialNumberInfo.offerPrice
    cartCard.sealedPrice = cardInfo.serialNumberInfo.sealedPrice
    cartCard.isUsed = cardInfo.isUsed
    cartCard.serialNumberInfo = cardInfo.serialNumberInfo

    //globals.orderInfo.items.push(cardInfo)
    for (let i = 0; i < clone.items.length; i++) {
      if (clone.items[i].code === cartCard.code
        && clone.items[i].serialNumberInfo.serial === cartCard.serialNumberInfo.serial
        && clone.items[i].serialNumberInfo.start === cartCard.serialNumberInfo.start) {
        repeat = true
        if (cartCard.isUsed) {
          clone.items[i].count += 1
          //clone.items[i] = Object.assign(clone.items[i], {count: clone.items[i].count + 1});
        } else {
          clone.items[i].countMint += 1
          //clone.items[i] = Object.assign(clone.items[i], {countMint: clone.items[i].countMint + 1});
        }
      }
    }

    if (!repeat) {
      if (cartCard.isUsed) {
        cardInfo.card.count = 1
        cardInfo.card.countMint = 0
        cartCard.count = 1
        cartCard.countMint = 0
      } else {
        cardInfo.card.count = 0
        cardInfo.card.countMint = 1
        cartCard.count = 0
        cartCard.countMint = 1
      }

      clone.items = [...clone.items, cartCard]
    }

    this.setState({ ...this.state, orderInfo: clone })
  }

  constructor(props: IContextProps) {
    super(props)
    this.state = {
      language: 'el',
      imageSize: 'normal',
      orderInfo: { items: new Array<ICartItem>(), name: '', address: '', city: '', postalCode: '', phone: '', email: '', comments: '', paymentType: '' },
      changeImageSize: this.changeImageSize,
      calcTotal: this.calcTotal,
      addItemToCart: this.addItemToCart,
      changeQuantity: this.changeQuantity,
      sendEmail: this.sendEmail,
      changeValue: this.changeValue
    }
  }

  changeLocale = (locale: string) => {
    this.setState({ language: locale })
  }

  render() {
    return (
      <PhonecardsContext.Provider value={{ ...this.state, changeLocale: this.changeLocale }}>
        {this.props.children}
      </PhonecardsContext.Provider>
    )
  }
}