import axios from 'axios'
import { isPHP } from '../common/constants'

export const getCalendar = (category) => {
  let url = `/api/statistics/${category}`

  if (isPHP) {
    url = `/api/statistics.php?type=${category}`
  }

  axios.get(url)
    .then((response) => {
      console.log(response.data)
      return response.data
    })
    .catch(error => {
      console.log('----------------------------------------------------', error)
      return 'error'
    })
}

export const getCardInfo = (cardCode) => {
  axios.get(`/api-info/phonecardInfo/${cardCode}`)
}

export const updateDescription = (card) => {
    var headers = {
        'Content-Type': 'application/json'
    }
    return axios.post('/api-update/phonecards/description', card, { headers: headers })
}