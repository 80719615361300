import React from 'react'
import { Button } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

interface IProps {
    url: string
    id: string
}

const TranslatedButton: React.FC<IProps> = props => {
  return (
    <Button tag={Link} to={props.url ? props.url : 'test'} style={{ color: 'white', textDecoration: 'none' }}>
      <FormattedMessage id={props.id}
                        defaultMessage="Welcome to {what}"
                        description="some description"
                        values={{ what: 'phonecards.gr' }}/>
      </Button>
  )
}

export default TranslatedButton