import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Button, ButtonGroup, Collapse, Nav, NavbarToggler, NavItem, NavLink } from 'reactstrap'
import { PhonecardsContext } from '../contexts/PhonecardsContext.tsx'
import TranslatedNavbarLink from './TranslatedNavbarLink'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { formatAmount } from '../utils/utils.js'

export default class Navbar extends React.Component {

    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false
        }
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <PhonecardsContext.Consumer>
                {({ changeLocale, calcTotal }) => (
                    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                        <div className="navbar-collapse collapse w-50 order-1 order-md-0 dual-collapse2">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active d-md-none d-lg-block">
                                    <a className="navbar-brand" href="http://www.phonecards.gr">phonecards.gr</a>
                                </li>
                                <li className="nav-item">
                                    <ButtonGroup>
                                        <Button onClick={() => changeLocale('el')}>Ελληνικά</Button>
                                        <Button onClick={() => changeLocale('en')}>English</Button>
                                    </ButtonGroup>
                                </li>
                            </ul>
                        </div>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <TranslatedNavbarLink id="nav.home" to="/"/>
                                <NavItem><NavLink tag={Link} to="/flyers">Flyers</NavLink></NavItem>
                                <TranslatedNavbarLink id="nav.history"
                                                      to="/cards-history"
                                                      className="d-none d-xl-block"/>
                                <NavItem>
                                    <NavLink target="_blank" href="https://www.ebay.com/sch/levem2014/m.html?_nkw=&_armrs=1&_ipg=&_from=">ebay</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink target="_blank" href="https://www.delcampe.net/en_GB/collections/store/grphonecards">delcampe</NavLink>
                                </NavItem>
                                <TranslatedNavbarLink id="nav.contact" to="/"/>
                                <NavItem>
                                    <Link to="/shopping-cart" className="nav-link active">
                                        <FormattedMessage id="text.my.list"/>
                                        <FontAwesomeIcon style={{ color: '#688bef' }} icon={faShoppingCart}/>
                                        {formatAmount(calcTotal())}
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </nav>
                )}
            </PhonecardsContext.Consumer>
        )
    }
}