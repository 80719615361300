import React, { PureComponent } from 'react'
import { PhonecardsContext } from '../contexts/PhonecardsContext'
import CardList from '../components/CardList'
import Header from '../components/Header'
import { linksMap } from '../common/constants'
import CardCalendar from '../components/CardCalendar'
import { FormattedMessage } from 'react-intl'

interface IProps {
  location: any
  match: any
}

interface ILocalState {
  calendarVisible: boolean
}

export default class CardContainer extends PureComponent<IProps, ILocalState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      calendarVisible: false
    }
  }

  toggle = (visible: boolean) => {
    this.setState(() => ({ calendarVisible: visible }))
  }

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ ...this.state, calendarVisible: false })
    }
  }

  render() {
    let finalUrl = this.props.match.url.substring(0, this.props.match.url.indexOf('/', 7))
    finalUrl = finalUrl ? finalUrl : this.props.match.url

    // @ts-ignore
    return (
      <PhonecardsContext.Consumer>
        {({ language }: {language: string}) => (
          <>
            <Header language={language} title={linksMap[finalUrl].headerId}/>
            <div className="text-center mb-4 no-print">
              <div className="btn-group" role="group">
                <button type="button" className="btn btn-primary" onClick={() => this.toggle(false)}><FormattedMessage id="text.catalog"/></button>
                <button type="button" className="btn btn-primary" onClick={() => this.toggle(true)}><FormattedMessage id="text.calendar"/></button>
              </div>
            </div>
            <CardCalendar category={this.props.match.params.category} visible={this.state.calendarVisible}/>
            <CardList visible={!this.state.calendarVisible}/>
          </>
        )}
      </PhonecardsContext.Consumer>
    )
  }

}
