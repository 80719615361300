import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

interface IProps {
    className: string
    to: string
    id: string
}

const TranslatedNavbarLink: React.FC<IProps> = props => {
  return (
    <li className={'nav-item ' + props.className}>
      <Link className="nav-link" to={props.to}>
        <FormattedMessage id={props.id} />
      </Link>
    </li>
  )
}

export default TranslatedNavbarLink