import React from 'react'
import DataComponent from '../hoc/DataComponent'
import { baseUrl } from '../common/constants'
import { formatAmount, getImagePath } from '../utils/utils.js'
import { PhonecardsContext } from '../contexts/PhonecardsContext.tsx'
import Header from './Header'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SuperOffersDeck = (props) => {
  return (
      <PhonecardsContext.Consumer>
        {({ language, addItemToCart }) => (
          <div className="container">
              <Header language={language} title="title.special.offers"/>
      {/*    <CardDeck>
            <Card>
              <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180" alt="Card image cap" />
              <CardBody>
                <CardTitle>Card title</CardTitle>
                <CardSubtitle>Card subtitle</CardSubtitle>
                <CardText>This is a wia little bit longer.</CardText>
                <Button>Button</Button>
              </CardBody>
            </Card>
            <Card>
              <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180" alt="Card image cap" />
              <CardBody>
                <CardTitle>Card title</CardTitle>
                <CardSubtitle>Card subtitle</CardSubtitle>
                <CardText>This card has supporting text below as a natural lead-in to additional content.</CardText>
                <Button>Button</Button>
              </CardBody>
            </Card>
            <Card>
              <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180" alt="Card image cap" />
              <CardBody>
                <CardTitle>Card title</CardTitle>
                <CardSubtitle>Card subtitle</CardSubtitle>
                <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</CardText>
                <Button>Button</Button>
              </CardBody>
            </Card>
            <Card>
              <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180" alt="Card image cap" />
              <CardBody>
                <CardTitle>Card title</CardTitle>
                <CardSubtitle>Card subtitle</CardSubtitle>
                <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
                <Button>Button</Button>
              </CardBody>
            </Card>
            <Card>
              <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180" alt="Card image cap" />
              <CardBody>
                <CardTitle>Card title</CardTitle>
                <CardSubtitle>Card subtitle</CardSubtitle>
                <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
                <Button>Button</Button>
              </CardBody>
            </Card>
          </CardDeck>*/}


            <div className="container-fluid mt-4">
              <div className="row justify-content-center">

                {
                  props.data && props.data.map((card, i) => {
                    return (
                      <div className="col-auto mb-3">
                        <div className="card" style={{ width: '18rem' }}>
                          <div className="card-body">
                            <h5 className="card-title">{language === 'el' ? card.descrGR : card.descr}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{card.code}</h6>
                            <img className="card-image-top" src={getImagePath(card, 'c')} alt="Front Side"/>
                            <a href="#" className="card-link">{formatAmount(card.offerPrice)}</a>
                            <a href="#"
                               onClick={() => addItemToCart({ card: card, serialNumberInfo: card.serialNumbers[0], isUsed: true })}
                            >
                              <FontAwesomeIcon className="add-to-cart-used" style={{ color: '#688bef' }} icon={faShoppingCart} />
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )}
      </PhonecardsContext.Consumer>
  )
}

export default React.memo(DataComponent(SuperOffersDeck, baseUrl + '/superOffers'))