export const environment = {
  1: 'phonecards',
  4: 'exhibition',
  5: 'phonecards',
  6: 'ote_prepaid',
  7: 'international_prepaid',
  8: 'mobile',
  9: 'cyprus',
  'phonecards': 'Τηλεκάρτες ΟΤΕ',
  'collectibles': 'Συλλεκτικές ΟΤΕ',
  'ex': 'Εκθεσιακές Τηλεκάρτες',
  'chronocards': 'Χρονοκάρτες',
  'cyprus': 'Τηλεκάρτες Κύπρου',
  'vf': 'VF Cards',
  'dl': 'DL Cards',
  'str': 'Starbucks',
  'otn': 'Κάρτες OTEnet',
  'cos': 'Κάρτες Cosmote',
  'pan': 'Κάρτες Vodafone',
  'tel': 'Κάρτες Wind'
}

export const getImagePath = (card, letter) => {
  return `/images/${environment[card.tp]}/${card.code}${letter}.jpg`
}

export const filterResult = (card, searchKeyword) => (card.descr && card.descr.toUpperCase().indexOf(searchKeyword.toUpperCase()) >= 0) || card.descrGR.toUpperCase().indexOf(searchKeyword.toUpperCase()) >= 0
  || card.code.toUpperCase().indexOf(searchKeyword.toUpperCase()) >= 0 || card.serialNumbersStr.toUpperCase().indexOf(searchKeyword.toUpperCase()) >= 0

export const formatQuantityNumber = number => !number || number === 0 ? '' : new Intl.NumberFormat('el-GR', {
  }).format(number)

export const formatAmount = amount => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(amount)

export const formatNumber = number => new Intl.NumberFormat('el-GR').format(number)
