import React, { PureComponent } from 'react'
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import TranslatedButton from './TranslatedButton'
import { PhonecardsContext } from '../contexts/PhonecardsContext.tsx'
import { Link } from 'react-router-dom'

export default class MainMenu extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }))
  }

  render() {
    return (
      <PhonecardsContext.Consumer>
        {() => (
          <ButtonGroup className="flex-wrap">
            <TranslatedButton id="menu.phonecards" url="/cards/phonecards"/>
            <TranslatedButton id="menu.ote.collectibles" url="/cards/collectibles"/>
            <TranslatedButton id="menu.cyprus.phonecards" url="/cards/cyprus"/>
            <TranslatedButton id="menu.chronocards" url="/cards/chronocards"/>
            <TranslatedButton id="menu.exhibiton.cards" url="/cards/ex"/>
            <Button tag={Link} to="/cards/str">Starbucks</Button>
            <Button tag={Link} to="/cards/dl">DL Cards</Button>
            <Button tag={Link} to="/cards/vf">VF Cards</Button>
            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret>Prepaid</DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/cards/otn">OTEnet</DropdownItem>
                <DropdownItem tag={Link} to="/cards/cos">Cosmote</DropdownItem>
                <DropdownItem tag={Link} to="/cards/pan">Vodafone</DropdownItem>
                <DropdownItem tag={Link} to="/cards/tel">Wind</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <TranslatedButton id="menu.offers" url="/cards/offers"/>
            <TranslatedButton id="menu.mint" url="/cards/mint"/>
            <TranslatedButton id="menu.errors" url="/cards/errors"/>
          </ButtonGroup>
        )}
      </PhonecardsContext.Consumer>
    )
  }

}
