import React, { PureComponent } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import axios from 'axios'
import { PhonecardsContext } from '../contexts/PhonecardsContext.tsx'
import Header from '../components/Header'
import { updateDescription } from '../api/api.js'


export default class UpdateInfoContainer extends PureComponent {

  constructor(props) {
    super(props)
    this.state = { cardCode: '' }
  }

  addSerialNumber = () => {
    const newCardInfo = { ...this.state.cardInfo }
    newCardInfo.serialNumbers = [...this.state.cardInfo.serialNumbers, {}]

    this.setState({ ...this.state, cardInfo: newCardInfo })
  }

  deleteSerialNumber = serialNumber => {
    const newCardInfo = { ...this.state.cardInfo }
    newCardInfo.serialNumbers = [...this.state.cardInfo.serialNumbers]

    var index = newCardInfo.serialNumbers.findIndex(i => i.serial === serialNumber.serial && i.aa === serialNumber.aa && i.start === serialNumber.start && i.end === serialNumber.end)
    if (index !== -1) {
      newCardInfo.serialNumbers.splice(index, 1)
      this.setState({ ...this.state, cardInfo: newCardInfo })
    }
  }

  handleChange = event => {
    this.setState({ cardCode: event.target.value })
    if (event.key === 'Enter') {
      axios.get(`/api-info/phonecardInfo/${event.target.value}`)
        .then((response) => {
          console.log('card info is:', response.data)
        this.setState({ ...this.state, cardInfo: response.data })
      })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  handleCardInfoChange = (field, value) => {
    if (field === 'descr') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, descr: value } })
    } else if (field === 'descrGR') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, descrGR: value } })
    } else if (field === 'm') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, m: value } })
    } else if (field === 'y') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, y: value } })
    } else if (field === 'units') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, units: value } })
    } else if (field === 'price') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, price: value } })
    } else if (field === 'sealedPrice') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, sealedPrice: value } })
    } else if (field === 'offerPrice') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, offerPrice: value } })
    } else if (field === 'generalComments') {
      this.setState({ ...this.state, cardInfo: { ...this.state.cardInfo, generalComments: value } })
    }
  }

  handleCardInfoSerialNumberChange = (field, value, index) => {
    const newCardInfo = { ...this.state.cardInfo }

    if (field === 'price') {
      newCardInfo.serialNumbers[index].price = value
    } else if (field === 'sealedPrice') {
      newCardInfo.serialNumbers[index].sealedPrice = value
    } else if (field === 'offerPrice') {
      newCardInfo.serialNumbers[index].offerPrice = value
    } else if (field === 'comments') {
      newCardInfo.serialNumbers[index].comments = value
    } else if (field === 'start') {
      newCardInfo.serialNumbers[index].start = value
    } else if (field === 'end') {
      newCardInfo.serialNumbers[index].end = value
    } else if (field === 'aa') {
      newCardInfo.serialNumbers[index].aa = value
    } else if (field === 'serial') {
      newCardInfo.serialNumbers[index].serial = value
    } else if (field === 'qty') {
      newCardInfo.serialNumbers[index].qty = value
    } else if (field === 'chipImagePath') {
      newCardInfo.serialNumbers[index].chipImagePath = value
    }

    this.setState({ ...this.state, cardInfo: newCardInfo })
  }

  updateCardInfo = cardInfo => {
    updateDescription(cardInfo)
      .then((response) => {
        console.log(response)
        alert(response.statusText)
      })
      .catch((error) => {
        alert(error)
      })

  }

  render() {
    return (
      <PhonecardsContext.Consumer>
        {({ language }) => (
          <>
            <Header language={ language } title="Update Card Info"/>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="cardCode">Card Code</Label>
                  <Input type="text" name="cardCode" id="cardCode" placeholder="Enter card code" onKeyPress={this.handleChange}/>
                </FormGroup>
              </Col>
            </Row>

            {this.state.cardInfo &&
              <>
                <Row form>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="code" sm={5}>Κωδικός</Label>
                      <Col sm={7}>
                        <Input type="text" name="code" id="code" disabled value={this.state.cardInfo.code}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="code" sm={5}>Περιγραφή (Ελληνικά)</Label>
                      <Col sm={7}>
                        <Input type="text" name="code" id="code" value={this.state.cardInfo.descrGR} onChange={(event) => this.handleCardInfoChange('descrGR', event.target.value)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="code" sm={5}>Περιγραφή (Αγγλικά)</Label>
                      <Col sm={7}>
                        <Input type="text" name="code" id="code" value={this.state.cardInfo.descr} onChange={(event) => this.handleCardInfoChange('descr', event.target.value)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="m" sm={5}>Μήνας/Έτος</Label>
                        <Col sm={3}>
                        <Input type="text" name="m" id="m" value={this.state.cardInfo.m} onChange={(event) => this.handleCardInfoChange('m', event.target.value)}/>
                        </Col>
                        <Col sm={4}>
                        <Input type="text" name="y" id="y" value={this.state.cardInfo.y} onChange={(event) => this.handleCardInfoChange('y', event.target.value)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="units" sm={5}>Μονάδες/Αξία</Label>
                      <Col sm={4}>
                        <Input type="text" name="units" id="units" value={this.state.cardInfo.units} onChange={(event) => this.handleCardInfoChange('units', event.target.value)}/>
                      </Col>
                      <Label for="units" sm={5}>Γενικά Σχόλια</Label>
                      <Col sm={7}>
                        <Input type="text" name="generalComments" id="generalComments" value={this.state.cardInfo.generalComments} onChange={(event) => this.handleCardInfoChange('generalComments', event.target.value)}/>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup row>
                      <Label for="units" sm={4}>Τιμή Ανοιχτής</Label>
                      <Col sm={4}>
                        <Input type="text" name="units" id="units" value={this.state.cardInfo.price} onChange={(event) => this.handleCardInfoChange('price', event.target.value)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="units" sm={4}>Τιμή Κλειστής</Label>
                      <Col sm={4}>
                        <Input type="text" name="units" id="units" value={this.state.cardInfo.sealedPrice} onChange={(event) => this.handleCardInfoChange('sealedPrice', event.target.value)}/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="units" sm={4}>Τιμή Προσφοράς</Label>
                      <Col sm={4}>
                        <Input type="text" name="units" id="units" value={this.state.cardInfo.offerPrice} onChange={(event) => this.handleCardInfoChange('offerPrice', event.target.value)}/>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Button onClick={this.addSerialNumber} color="primary" >Προσθήκη Αρίθμησης</Button>

                <h4>Αριθμήσεις</h4>

                <table>
                  <thead>
                    <th></th>
                    <th>A/A</th>
                    <th>Αρίθμηση</th>
                    <th>Από</th>
                    <th>Έως</th>
                    <th>Ποσότητα</th>
                    <th>Chip</th>
                    <th>Σχόλια</th>
                    <th>Ανοιχτή</th>
                    <th>Κλειστή</th>
                    <th>Προσφορά</th>
                  </thead>
                  <tbody>
                {this.state.cardInfo.serialNumbers &&
                <>

                  { this.state.cardInfo.serialNumbers.map((serialNumber, index) => {
                    return (
                      <tr key={index}>
                        <td><Button onClick={() => this.deleteSerialNumber(serialNumber)} color="danger">X</Button></td>
                        <td><Input type="text" name="serialNumber.aa" value={serialNumber.aa ? serialNumber.aa : ''} onChange={(event) => this.handleCardInfoSerialNumberChange('aa', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.serial" value={serialNumber.serial} onChange={(event) => this.handleCardInfoSerialNumberChange('serial', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.start" value={serialNumber.start} onChange={(event) => this.handleCardInfoSerialNumberChange('start', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.end" value={serialNumber.end} onChange={(event) => this.handleCardInfoSerialNumberChange('end', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.end" value={serialNumber.qty} onChange={(event) => this.handleCardInfoSerialNumberChange('qty', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.chipImagePath" value={serialNumber.chipImagePath ? serialNumber.chipImagePath : ''} onChange={(event) => this.handleCardInfoSerialNumberChange('chipImagePath', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.comments" value={serialNumber.comments} onChange={(event) => this.handleCardInfoSerialNumberChange('comments', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.price" value={serialNumber.price} onChange={(event) => this.handleCardInfoSerialNumberChange('price', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.sealedPrice" value={serialNumber.sealedPrice} onChange={(event) => this.handleCardInfoSerialNumberChange('sealedPrice', event.target.value, index)}/></td>
                        <td><Input type="text" name="serialNumber.offerPrice" value={serialNumber.offerPrice} onChange={(event) => this.handleCardInfoSerialNumberChange('offerPrice', event.target.value, index)}/></td>
                      </tr>
                    )
                  })
                  }
                </>
                }
                  </tbody>
                </table>
              </>
            }
            <Button color="primary" onClick={() =>this.updateCardInfo(this.state.cardInfo)}>Update</Button>
          </>
        )}
      </PhonecardsContext.Consumer>
    )
  }

}
