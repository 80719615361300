import React from 'react'
import DataComponent from '../hoc/DataComponent'
import { baseUrl } from '../common/constants'
import { PhonecardsContext } from '../contexts/PhonecardsContext'
import Header from './Header'
import INewsItem from '../interfaces/INewsItem'

interface IProps {
  data: any
}

const LatestNewsPanel: React.FC<IProps> = (props: IProps) => {
  return (
    <PhonecardsContext.Consumer>
      {({ language }: {language: string}) => (
        <div className="container">
          <Header language={ language } title="title.latest.news"/>
          <div className="marquee-old">
            <p>
              <h5>
              {
                props.data && props.data.map((news: INewsItem) => {
                  return <h5 key={news.title}>{news.date} - {news.title}</h5>
                })
              }
              </h5>
            </p>
          </div>
        </div>
      )}
    </PhonecardsContext.Consumer>
  )
}

export default React.memo(DataComponent(LatestNewsPanel, baseUrl + '/news'))