import React, { PureComponent } from 'react'
import { PhonecardsContext } from '../contexts/PhonecardsContext'
import Header from '../components/Header'
import { linksMap } from '../common/constants'
import ShoppingCart from '../components/ShoppingCart'
import PersonalInfoForm from '../components/PersonalInfoForm'
import IOrderInfo from '../interfaces/IOrderInfo'

interface IProps {
  location: any
  match: any
}

export default class ShoppingCartContainer extends PureComponent<IProps> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      calendarVisible: false
    }
  }

  toggle = (visible: boolean) => {
    this.setState(() => ({ calendarVisible: visible }))
  }

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ ...this.state, calendarVisible: false })
    }
  }

  render() {
    let finalUrl = this.props.match.url.substring(0, this.props.match.url.indexOf('/', 7))
    finalUrl = finalUrl ? finalUrl : this.props.match.url
    //console.log('1234', finalUrl);

    return (
      <PhonecardsContext.Consumer>
        {({ language, orderInfo }: {language: string, orderInfo: IOrderInfo}) => (
          <>
            <Header language={ language } title={linksMap[finalUrl].headerId}/>
            <ShoppingCart items={orderInfo.items}/>
            <PersonalInfoForm/>
          </>
        )}
      </PhonecardsContext.Consumer>
    )
  }

}
