import React from 'react'

export default props => {
    return (
        <React.Fragment>
            { props.loading &&
                <div className="spinner fixed-loading-position">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div> 
            }
        </React.Fragment>
    )
  }
  